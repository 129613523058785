import Vapi from "@vapi-ai/web";
import { useEffect, useState } from "react";
import { isPublicKeyMissingError, toastError } from "../utils";

const vapi = new Vapi(process.env.REACT_APP_VAPI_PUBLIC_KEY);

function useVapi() {
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [assistantIsSpeaking, setAssistantIsSpeaking] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0);
  const [showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage] =
    useState(false);

  function handleCallStart() {
    setConnecting(false);
    setConnected(true);
    setShowPublicKeyInvalidMessage(false);
  }

  function handleCallEnd() {
    setConnecting(false);
    setConnected(false);
    setShowPublicKeyInvalidMessage(false);
  }

  function handleSpeechStart() {
    setAssistantIsSpeaking(true);
  }

  function handleSpeechEnd() {
    setAssistantIsSpeaking(false);
  }

  function handleVolumeLevel(level) {
    setVolumeLevel(level);
  }

  function handleError(error) {
    console.error(error);
    setConnecting(false);

    if (isPublicKeyMissingError({ vapiError: error })) {
      toastError("Hmm, seems like your Vapi Public Key is missing.");
    }
  }

  useEffect(() => {
    vapi.on("call-start", handleCallStart);
    vapi.on("call-end", handleCallEnd);
    vapi.on("speech-start", handleSpeechStart);
    vapi.on("speech-end", handleSpeechEnd);
    vapi.on("volume-level", handleVolumeLevel);
    vapi.on("error", handleError);

    // cleanup
    return () => {
      vapi.off("call-start", handleCallStart);
      vapi.off("call-end", handleCallEnd);
      vapi.off("speech-start", handleSpeechStart);
      vapi.off("speech-end", handleSpeechEnd);
      vapi.off("volume-level", handleVolumeLevel);
      vapi.off("error", handleError);
    };

    // we only want this to fire on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    vapi,
    connecting,
    setConnecting,
    connected,
    setConnected,
    assistantIsSpeaking,
    setAssistantIsSpeaking,
    volumeLevel,
    setVolumeLevel,
    showPublicKeyInvalidMessage,
    handleError,
  };
}

export default useVapi;
