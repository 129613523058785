import React from "react";

function Footer() {
  return (
    <footer className='w-full h-[5%] fixed bottom-0 bg-rpAccent grid place-items-center text-white'>
      ForwardLabs.ai &#169; 2024
    </footer>
  );
}

export default Footer;
