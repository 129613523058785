import React, { useState } from "react";
import Tooltip from "./Tooltip";
import { MenuItem, Select } from "@mui/material";

const countryCodes = [
  { code: "+1", country: "United States", icon: "🇺🇸" },
  { code: "+44", country: "United Kingdom", icon: "🇬🇧" },
  { code: "+91", country: "India", icon: "🇮🇳" },
  { code: "+81", country: "Japan", icon: "🇯🇵" },
  { code: "+61", country: "Australia", icon: "🇦🇺" },
  { code: "+49", country: "Germany", icon: "🇩🇪" },
  { code: "+33", country: "France", icon: "🇫🇷" },
  { code: "+86", country: "China", icon: "🇨🇳" },
  { code: "+34", country: "Spain", icon: "🇪🇸" },
  { code: "+39", country: "Italy", icon: "🇮🇹" },
  { code: "+65", country: "Singapore", icon: "🇸🇬" },
  { code: "+62", country: "Indonesia", icon: "🇮🇩" },
  { code: "+63", country: "Philippines", icon: "🇵🇭" },
].sort((a, b) => a.country.localeCompare(b.country));

const PhoneNumberInput = ({
  onChange,
  className = "",
  label = "",
  placeholder = "Phone Number",
  tooltip = "",
}) => {
  const [inputValue, setInputValue] = useState({
    code: countryCodes[0].code,
    number: "",
  });

  const handleCountryCodeChange = (e) => {
    setInputValue({ ...inputValue, code: e.target.value });
  };

  const handlePhoneNumberChange = (e) => {
    const number = e.target.value.match(/(?<=\s)\d+/)?.[0]; // Only allow numbers
    setInputValue({ ...inputValue, number: number || "" });
    if (onChange) {
      onChange(`${inputValue.code} ${number}`);
    }
  };

  return (
    <div className={className}>
      {label && (
        <label>
          {label} {tooltip && <Tooltip infoText={tooltip} />}
        </label>
      )}
      <div className='phone-number-input'>
        <Select
          value={inputValue.code}
          onChange={handleCountryCodeChange}
          size='small'
          sx={{
            background: "#fafffb",
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": { border: "none" },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
          renderValue={(selected) => {
            const country = countryCodes.find((c) => c.code === selected);
            return <span>{country.icon}</span>;
          }}
        >
          {countryCodes.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              <div className='flex items-center gap-2 divide-x-2'>
                <span>{country.icon}</span>
                <span className='text-xs pl-2'>{country.country}</span>
              </div>
            </MenuItem>
          ))}
        </Select>
        <input
          type='tel'
          value={`${inputValue.code} ${inputValue.number}`}
          onChange={handlePhoneNumberChange}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default PhoneNumberInput;
