import { useState } from "react";
import Tooltip from "./Tooltip";

const SingleLineInput = ({ label, name, className='', style={}, onChange=() => {}, disabled=false, placeholder='', defaultValue='', tooltip='' }) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <div className={className} style={style}>
      {label && <label>{label} {tooltip && <Tooltip infoText={tooltip} />}</label>}
      <div style={{display: "flex"}}>
        <input
          type="text"
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default SingleLineInput;
