import ScaleLoader from "react-spinners/ScaleLoader";
import { getTheme } from "../../utils";

const Button = ({ label, onClick, isLoading, disabled, className }) => {
  const Contents = isLoading ? (
    <ScaleLoader
      color={getTheme("#009688", "white")}
      height={10}
      width={2.5}
      margin={0.5}
      loading={true}
      size={50}
      css={{ display: "block", margin: "0 auto" }}
    />
  ) : (
    <p style={{ margin: 0, padding: 0 }}>{label}</p>
  );

  return (
    <button disabled={disabled} onClick={onClick} className={className}>
      {Contents}
    </button>
  );
};

export default Button;
