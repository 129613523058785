import React, { useState } from "react";

const Tooltip = ({ infoText }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showTooltip = () => setIsVisible(true);
  const hideTooltip = () => setIsVisible(false);

  return (
    <div
      style={{
        display: "inline-block",
        position: "relative",
        paddingLeft: "2px",
      }}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      <span style={{ cursor: "pointer", fontSize: "10px" }}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='10'
          height='10'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='lucide lucide-info'
          data-id='19'
        >
          <circle cx='12' cy='12' r='10'></circle>
          <path d='M12 16v-4'></path>
          <path d='M12 8h.01'></path>
        </svg>
      </span>
      {isVisible && (
        <div
          style={{
            position: "absolute",
            bottom: "100%",
            left: "50%",
            transform: "translateX(-50%)",
            padding: "8px",
            backgroundColor: "black",
            color: "white",
            borderRadius: "4px",
            whiteSpace: "nowrap",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
            marginBottom: "8px",
            fontWeight: "lighter",
          }}
        >
          {infoText}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
