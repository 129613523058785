import React, { useState } from "react";
import { RoleplayCardDetails } from "./roleplay";
import { AllCategoriesCards, Card } from "./Card";
import { Navbar } from "../../../components";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function RoleplayDashboard() {
  const [selectedCategory, setSelectedCategory] = useState("All Categories");
  const categories = [
    "All Categories",
    ...Array.from(new Set(RoleplayCardDetails.map((card) => card.category))),
  ];

  const filteredCardDetails = RoleplayCardDetails.filter(
    (card) => card.category === selectedCategory
  );

  return (
    <div className='w-full h-[95%] p-4'>
      {/* Header */}
      <div className='flex justify-between w-full'>
        <div className='w-[180px] h-[70px] bg-rpSecondary rounded-t-xl'>
          <div className='relative size-full grid place-items-center font-semibold text-large text-wrap text-center'>
            Start Practice
            <img
              src='/happyApe.png'
              alt=''
              className='w-[120px] aspect-square absolute -top-10 -right-28 rotate-[35deg] -z-10'
            />
          </div>
        </div>

        <Navbar
          categories={categories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      </div>

      {/* Body */}
      <div className='relative w-full h-[calc(100%-70px)] bg-rpSecondary rounded-r-xl rounded-bl-xl py-2 px-2 md:px-6'>
        <h1 className='absolute bottom-0 right-0 text-rpAccent/20 text-xlarge font-semibold tracking-wide py-4 px-12 text-start z-0'>
          {selectedCategory}
        </h1>

        {filteredCardDetails.length > 0 && (
          <div className='w-full h-fit mt-4 flex items-center justify-center gap-3 p-4 bg-rpPrimary dark:bg-rpDark text-rpAccent text-medium font-normal tracking-wide rounded-lg shadow-md'>
            <InfoOutlinedIcon fontSize='large' />
            <p className='text-dark dark:text-white w-full text-left text-pretty py-2'>
              {filteredCardDetails[0].prospectInfo}
            </p>
          </div>
        )}

        {selectedCategory === "All Categories" ? (
          <AllCategoriesCards
            cardDetails={RoleplayCardDetails}
            setSelectedCategory={setSelectedCategory}
          />
        ) : (
          <Card
            cardDetails={filteredCardDetails}
            selectedCategory={selectedCategory}
          />
        )}
      </div>
    </div>
  );
}

export default RoleplayDashboard;
