export const ASSISTANT_IDS = {
  default: "ba1c36c1-ddb2-4716-abf6-5f8e84336db1",
  cs: "fb76083f-3d2f-44ea-84af-9f4a5bd5aed8",
  des: "2d1615be-e3c8-42b0-8204-ab5029937374",
};

export const getAssistantId = () => {
  const hostname = window.location.hostname;

  // for prod
  if (hostname.includes("your-coach-cs")) return ASSISTANT_IDS.cs;
  if (hostname.includes("your-coach-des")) return ASSISTANT_IDS.des;

  // for dev - test locally by adding a URL parameter
  if (hostname === "localhost") {
    const params = new URLSearchParams(window.location.search);
    const subdomain = params.get("subdomain");
    console.log("🔍 Local testing:", {
      subdomain: subdomain || "none",
      usingDefault: !subdomain,
    });

    if (subdomain === "your-coach-cs") {
      return ASSISTANT_IDS.cs;
    }
    if (subdomain === "your-coach-des") {
      return ASSISTANT_IDS.des;
    }
    return ASSISTANT_IDS.default;
  }

  return ASSISTANT_IDS.default;
};

export const getAssistantType = (assistantId) => {
  return {
    isRolePlay: assistantId === ASSISTANT_IDS.cs,
    isDes: assistantId === ASSISTANT_IDS.des,
  };
};
