export const isPublicKeyMissingError = ({ vapiError }) => {
  const errors = [
    { statusCode: 401, error: "Unauthorized" },
    { statusCode: 403, error: "Forbidden" },
  ];

  return (
    !!vapiError &&
    errors.some(
      ({ statusCode, error }) =>
        vapiError.error.statusCode === statusCode &&
        vapiError.error.error === error
    )
  );
};

export const getTheme = (darkTheme, lightTheme) => {
  return window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
    ? darkTheme
    : lightTheme;
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
