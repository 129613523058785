import { useState } from "react";
import { BaseModal, SingleLineInput } from "../../../components";
import { useFetch } from "../../../hooks";
import { delay, toastSuccess } from "../../../utils";
import { transformAnalysis } from "./roleplay";

import { Button } from "flowbite-react";
import ScaleLoader from "react-spinners/ScaleLoader";

function FormInput({ name, email, setName, setEmail, isLoading, onSubmit }) {
  return (
    <div className='form__box'>
      <SingleLineInput
        className='main-input'
        name={"name"}
        label={"Name"}
        placeholder='John Doe'
        onChange={setName}
      />
      <SingleLineInput
        className='main-input'
        name={"email"}
        label={"Email"}
        placeholder='your@email.com'
        onChange={setEmail}
      />

      <Button
        onClick={onSubmit}
        className={"submit-button mt-8 bg-rpAccent"}
        disabled={
          !email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g) || name?.length < 1
        }
      >
        {isLoading ? (
          <ScaleLoader
            color='white'
            height={10}
            width={2.5}
            margin={0.5}
            loading={true}
            size={50}
            css={{ display: "block", margin: "0 auto" }}
          />
        ) : (
          <>Submit</>
        )}
      </Button>
    </div>
  );
}

export default function ContactInfoModal({
  openModal,
  setOpenModal,
  callId,
  assistantId,
}) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const { isLoading, fetchData } = useFetch(
    `https://api.vapi.ai/call/${callId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_VAPI_PRIVATE_KEY}`,
      },
    }
  );

  const handleSubmit = async () => {
    try {
      const { result } = await fetchData();

      if (result.status === "in-progress") {
        delay(2500);
        return handleSubmit();
      }

      sendEmail(result.analysis);
      setOpenModal(false);
    } catch (err) {
      console.error("Error submitting form:", err);
      setOpenModal(false);
    }
  };

  const sendEmail = async (analysisData) => {
    try {
      let transformedAnalysis = transformAnalysis(analysisData, name) || "";
      transformedAnalysis = `<div style="white-space: pre-wrap;">${transformedAnalysis}</div>`;

      const emailData = {
        callId,
        assistantId,
        contactInfo: {
          name,
          email,
        },
        analysis: transformedAnalysis,
      };

      const response = await fetch(process.env.REACT_APP_ZAPIER_WEBHOOK_URL, {
        method: "POST",
        body: JSON.stringify(emailData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      toastSuccess("Gotcha! Your assessment is on its way.");
    } catch (err) {
      console.error("Error sending email:", err);
    }
  };

  return (
    <BaseModal
      isOpen={openModal}
      onClose={() => setOpenModal(false)}
      title="We'll send you an email with your personalised assessment of how you did."
      subtitle='If this is not the right time, feel free to give it another go.'
    >
      <FormInput
        name={name}
        email={email}
        setName={setName}
        setEmail={setEmail}
        isLoading={isLoading}
        onSubmit={handleSubmit}
      />
    </BaseModal>
  );
}
