import { useState } from "react";
import { getAssistantType } from "../utils";
import {
  ActiveCallDetail,
  Button,
  SingleLineInput,
  PhoneNumberInput,
} from "../components";
import { useVapi } from "../hooks";

export const CoachDefault = ({ assistantId }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const {
    vapi,
    connecting,
    setConnecting,
    connected,
    assistantIsSpeaking,
    volumeLevel,
    handleError,
  } = useVapi();

  const { isRolePlay } = getAssistantType(assistantId);

  // call start handler
  const startCallInline = () => {
    setConnecting(true);

    vapi
      .start(assistantId, {
        variableValues: {
          name,
          email,
          phoneNumber,
        },
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const endCall = () => {
    vapi.stop();
  };

  return (
    <main className='p-3 gradient--primary min-h-full w-full grid place-items-center'>
      <div className='logo--watermark bottom-0 right-10'>{">."}</div>
      <section className='container z-10'>
        {!connected ? (
          <div className='flex flex-col gap-7'>
            <h1 className='mb-2 text-wrap font-semibold flex flex-col gap-2'>
              Hi, I’m Luna your personal coach
              <small>Before we get started, tell me more about yourself!</small>
            </h1>
            {isRolePlay && (
              <div className='flex flex-col gap-1 text-slate-500 text-pretty border-y-2 border-dotted pt-4 pb-6 dark:text-white/80'>
                <p className='font-bold tracking-wide'>Roleplay</p>
                <span>
                  1. You’re speaking with the founder of HotelsAtHome.
                </span>
                <span>
                  2. This is an initial discovery call about payment solutions.
                </span>
              </div>
            )}

            <div className='form__box'>
              <SingleLineInput
                className='main-input'
                name={"name"}
                label={"Name"}
                placeholder='John Doe'
                onChange={setName}
                disabled={connected}
              />
              <SingleLineInput
                className='main-input'
                name={"email"}
                label={"Email"}
                placeholder='your@email.com'
                onChange={setEmail}
                disabled={connected}
                tooltip={
                  <small>
                    I’ll send post-call notes and resources straight to your
                    inbox
                  </small>
                }
              />
              <PhoneNumberInput
                className='main-input'
                label={"Phone number"}
                onChange={setPhoneNumber}
                tooltip={
                  <small>
                    I’ll call occasionally to check in on your progress and
                    offer support
                  </small>
                }
              />
              <Button
                label={<span>Speak to Luna</span>}
                onClick={startCallInline}
                isLoading={connecting}
                className={"submit-button mt-8"}
                disabled={
                  !email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g) ||
                  name?.length < 1 ||
                  phoneNumber?.length < 10
                }
              />
            </div>
          </div>
        ) : (
          <ActiveCallDetail
            assistantIsSpeaking={assistantIsSpeaking}
            volumeLevel={volumeLevel}
            onEndCallClick={endCall}
          />
        )}
      </section>
    </main>
  );
};
