import { Modal } from "flowbite-react";

export default function BaseModal({
  isOpen,
  onClose,
  children,
  title,
  subtitle,
  className = "z-40 max-w-lg mx-auto my-auto h-fit",
}) {
  return (
    <>
      {isOpen && <div className='fixed inset-0 bg-dark/55 z-30'></div>}
      <Modal
        dismissible
        show={isOpen}
        onClose={onClose}
        position='center'
        className={className}
      >
        {(title || subtitle) && (
          <Modal.Header className='mt-10 pt-4 px-8'>
            <p className='text-large text-rpAccent flex flex-col gap-3 font-semibold text-balance'>
              {title}
              {subtitle && (
                <small className='text-medium text-dark font-light'>
                  {subtitle}
                </small>
              )}
            </p>
          </Modal.Header>
        )}
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
}
