import React from "react";
import { Link } from "react-router-dom";
import { CategoryCard } from "../../../components";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

export function DetailCard({ detail, category, prospectInfo }) {
  return (
    <div className='relative w-full h-5/6 p-2 flex items-start justify-center'>
      <img
        src={detail.image}
        alt={detail.title}
        className='opacity-5 absolute inset-0 size-full -z-10'
      />

      <div className='h-full w-full lg:w-1/2 max-w-[450px] flex flex-col justify-center gap-4'>
        <h1 className='text-dark font-semibold tracking-wide text-left py-2 mt-6'>
          {detail.title}
        </h1>

        <div className='sm:overflow font-medium text-default'>
          {detail.description ? (
            detail.description.split("\n\n").map((paragraph, br) => (
              <p key={br} style={{ marginBottom: "1rem" }}>
                {paragraph}
              </p>
            ))
          ) : (
            <p>No description available.</p>
          )}

          <p className='flex items-start gap-2 bg-white dark:bg-rpDark p-6 rounded-lg z-20 text-rpAccent text-pretty'>
            <TaskAltIcon fontSize='small' />
            <small className='text-rpAccentBrown dark:text-white'>
              {detail.goal}
            </small>
          </p>
        </div>

        <Link
          to='/demo/practice'
          state={{
            category,
            prospectInfo,
            title: detail.title,
            description: detail.description,
            goal: detail.goal,
            assistantId: detail.assistantId,
          }}
          className='button__retro mt-4 hover:scale-105 font-semibold bg-rpAccent text-white z-20'
        >
          Start
        </Link>
      </div>
    </div>
  );
}

export function Card({ cardDetails, selectedCategory }) {
  return (
    <div className='w-full h-5/6 max-h-[60%] sm:max-h-[75%] xl:max-h-full overflow px-2'>
      {cardDetails.map((category, index) => (
        <div
          key={index}
          className='min-h-full flex flex-col lg:flex-row items-center justify-evenly divide-dark/65 lg:divide-x-2'
        >
          {category.details.map((detail, detailIndex) => (
            <DetailCard
              key={detailIndex}
              detail={detail}
              category={selectedCategory}
              prospectInfo={category.prospectInfo}
            />
          ))}
        </div>
      ))}
    </div>
  );
}

export function AllCategoriesCards({ cardDetails, setSelectedCategory }) {
  return (
    <div className='w-full h-[90%]  flex flex-col lg:grid grid-cols-2 gap-6 p-8 overflow'>
      {cardDetails.map((category, index) => (
        <CategoryCard
          key={index}
          category={category}
          onClick={() => setSelectedCategory(category.category)}
        />
      ))}
    </div>
  );
}
