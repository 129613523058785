import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import WestIcon from "@mui/icons-material/West";

const Error = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  let errorMessage = "";
  let description = "";

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      errorMessage = "This page doesn’t exist.";
      description =
        "Don’t worry, you haven’t been transported to a parallel universe...yet.";
    } else if (error.status === 401) {
      errorMessage = "You aren’t allowed on this page.";
      description =
        "Try again after unlocking the hidden treasure of permissions.";
    } else if (error.status === 503) {
      errorMessage = "Our system is taking a nap. Try again in a bit.";
      description = "It’s not you, it’s us... but we’ll be back soon!";
    } else if (error.status === 418) {
      errorMessage = "🍌";
      description =
        "The monkey got distracted by a banana and forgot about your request. Please wait a moment!";
    }
  } else {
    errorMessage =
      error.statusText ||
      "Banana break! The monkey can't process your request right now.";
    description = error.message || "He'll calm down in a second.";
  }

  return (
    <main className='size-full min-h-full grid place-items-center bg-gradient-to-br from-stone-700 via-stone-800 to-dark font-nunito'>
      <div className='logo--watermark bottom-0 right-10 text-white/20'>
        {">."}
      </div>

      <section className='flex flex-col gap-14 items-start justify-center p-8 z-10'>
        <div className='flex flex-col items-start sm:items-center'>
          <img
            src='/ape.png'
            alt='ape-oops'
            className='brightness-75 min-w-[180px] w-1/2 max-w-[350px] aspect-square animate-shake'
          />

          <h1 className='w-full text-left text-xlarge font-bold tracking-wider text-balance text-white z-20'>
            Oopsie! {errorMessage}
          </h1>

          <h2 className='w-full text-left text-large text-balance mt-2 px-px opacity-85 z-20 text-white/85'>
            {description}
          </h2>
        </div>

        <button
          onClick={() => {
            navigate(-1);
          }}
          className='button__retro bg-stone-300 dark:bg-stone-600 flex gap-3 font-bold'
        >
          <WestIcon fontSize='large' />
          Let's go back
        </button>
      </section>
    </main>
  );
};

export default Error;
