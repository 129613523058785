import { useState } from "react";
import axios from "axios";
import { delay } from "../utils";

export default function useFetch(url, options = {}) {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchData(requestBody) {
    try {
      setIsLoading(true);

      await delay(5000);

      const response = await axios({
        url,
        method: options.method || "GET",
        data: requestBody,
        headers: {
          ...options.headers,
        },
        ...options,
      });

      setData(response.data);
      return { response, result: response.data };
    } catch (error) {
      setError(error.response?.data?.message || error.message);
    } finally {
      setIsLoading(false);
    }
  }

  return { data, error, setError, isLoading, fetchData };
}
