import React from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "../components";

function RoleplayLayout() {
  return (
    <>
      <main className='size-full flex bg-rpAccent p-2 dark:text-rpDark'>
        <section className='overflow h-[95%] w-full dark:bg-rpDark bg-rpPrimary dark:bg-grid-small-white/[0.2] bg-grid-small-black/[0.2] relative flex items-center justify-center rounded-2xl shadow-lg'>
          {/* fixed styles */}
          <div className='absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-rpDark bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)] z-0 rounded-xl'></div>
          <h1 className='fixed top-6 left-6 text-4xl font-nunito font-extrabold tracking-wide text-rpAccent px-2'>
            {">."}
          </h1>

          <div className='w-full h-full flex flex-col justify-end items-center gap-1.5 z-10 pt-4'>
            <Outlet />
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}

export default RoleplayLayout;
