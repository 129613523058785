import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Accordion } from "flowbite-react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import TaskIcon from "@mui/icons-material/Task";
import MicIcon from "@mui/icons-material/Mic";
import ScaleLoader from "react-spinners/ScaleLoader";

import { useVapi } from "../../../hooks";
import ContactInfoModal from "./ContactInfoModal";

function Practice() {
  const location = useLocation();
  const { category, prospectInfo, title, description, goal, assistantId } =
    location.state || {};
  const playerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { vapi, connecting, setConnecting, connected, handleError } = useVapi();
  const [callId, setCallId] = useState("");

  // call start handler
  const startCallInline = () => {
    setConnecting(true);

    vapi
      .start(assistantId)
      .then((webCall) => {
        if (webCall) {
          setCallId(webCall.id);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const endCall = () => {
    vapi.stop();
    setIsModalOpen(true);
  };

  const handleTogglePlay = () => {
    if (playerRef.current) {
      if (connected) {
        endCall();
        playerRef.current.stop();
      } else {
        startCallInline();
      }
    }
  };

  useEffect(() => {
    if (connected && playerRef.current) {
      playerRef.current.play();
    }
  }, [connected]);

  return (
    <>
      <div className='w-full h-[95%] p-4 text-white'>
        <div className='flex justify-between w-full'>
          <div className='w-[180px] h-[70px] bg-rpAccentBrown rounded-t-xl'>
            <div className='relative size-full grid place-items-center font-semibold text-default text-center text-wrap'>
              {title}
              <img
                src='/glassesApe.png'
                alt=''
                className='w-[120px] aspect-square absolute -top-10 -right-28 rotate-[35deg] -z-10'
              />
            </div>
          </div>
        </div>

        {/* Body */}
        <div className='relative w-full h-[calc(100%-70px)] bg-rpAccentBrown rounded-r-xl rounded-bl-xl py-2 px-2 md:px-6'>
          <div className='absolute top-0 right-2 w-full h-[10%] flex items-end justify-end gap-2'>
            <Link
              to='/demo'
              className='rpButtons__category flex gap-2 items-center justify-center w-fit px-8'
            >
              <HomeOutlinedIcon fontSize='large' />
            </Link>
          </div>

          <h1 className='absolute bottom-0 right-0 text-white/45 text-xlarge font-semibold tracking-wide py-4 px-12 text-start'>
            {category}
          </h1>
          <div className='flex flex-col-reverse lg:flex-row py-8 mt-6 lg:mt-0 size-full '>
            <div className='w-full h-full flex flex-col lg:justify-center gap-12 text-medium text-pretty p-4 lg:p-8 overflow'>
              <div className='flex lg:hidden overflow w-full'>
                <Accordion
                  collapseAll
                  className='bg-rpPrimary text-dark w-full'
                >
                  <Accordion.Panel>
                    <Accordion.Title className='py-4 px-2 bg-rpPrimary dark:text-dark rounded-md'>
                      Roleplay Setup
                    </Accordion.Title>
                    <Accordion.Content>
                      <div className='flex flex-col gap-4 text-medium font-light p-4'>
                        {description.split("\n\n").map((paragraph, br) => (
                          <p className='text-medium' key={br}>
                            {paragraph}
                          </p>
                        ))}
                        <p>{prospectInfo}</p>
                      </div>
                    </Accordion.Content>
                  </Accordion.Panel>

                  <Accordion.Panel>
                    <Accordion.Title className='py-4 px-2 bg-rpPrimary dark:text-dark  rounded-md'>
                      Your Goal
                    </Accordion.Title>
                    <Accordion.Content>
                      <p className='text-dark font-light p-4'>{goal}</p>
                    </Accordion.Content>
                  </Accordion.Panel>
                </Accordion>
              </div>

              {/* for lg screen size and above */}
              <div className='hidden lg:flex lg:flex-col lg:gap-12'>
                <div className='flex flex-col gap-4 text-default text-white font-light'>
                  <p className='text-left text-large underline underline-offset-4'>
                    Roleplay setup
                  </p>
                  {description.split("\n\n").map((paragraph, br) => (
                    <p className='text-default' key={br}>
                      {paragraph}
                    </p>
                  ))}
                  <p>{prospectInfo}</p>
                </div>

                <div className='flex items-start gap-2 bg-rpPrimary text-rpAccent p-6 rounded-lg z-20 text-pretty'>
                  <TaskIcon fontSize='large' />
                  <p className='font-medium text-dark'>{goal}</p>
                </div>
              </div>
            </div>
            <div className='w-full h-full flex flex-col items-center justify-center gap-2'>
              <div className='relative w-[65%] max-w-[300px] aspect-square'>
                <dotlottie-player
                  ref={playerRef}
                  src='https://lottie.host/f5310496-6487-472a-aede-d6c01cf9f1b9/49WPDV31z5.json'
                  background='transparent'
                  speed='0.5'
                  style={{ width: "100%", height: "100%" }}
                  loop
                  // autoplay
                />
                <span
                  onClick={!connecting ? handleTogglePlay : undefined}
                  className={`absolute inset-0 flex flex-col items-center justify-center gap-2 text-rpAccentBrown font-semibold text-medium lg:text-default uppercase cursor-pointer ${
                    connecting ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  {connecting ? (
                    <ScaleLoader
                      color='#7a6b4b'
                      height={10}
                      width={2.5}
                      margin={0.5}
                      loading={true}
                      size={50}
                      css={{ display: "block", margin: "0 auto" }}
                    />
                  ) : (
                    <>
                      <MicIcon fontSize='large' />
                      {connected ? "Stop" : "Start"}
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>

          {/* end of Body */}
        </div>

        {/*  */}
      </div>

      <ContactInfoModal
        openModal={isModalOpen}
        setOpenModal={setIsModalOpen}
        callId={callId}
        assistantId={assistantId}
      />
    </>
  );
}

export default Practice;
