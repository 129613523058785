import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  CoachDefault,
  ErrorPage,
  PracticePage,
  RoleplayDashboard,
  SamplePage,
  SamplePageDes,
} from "./pages";
import { getAssistantId } from "./utils/assistantIds";
import { MainLayout, RoleplayLayout } from "./layout";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const currentAssistantId = getAssistantId();
  const hostname = window.location.hostname;

  let subdomain;
  // for dev
  // sample url: http://localhost:3000/?subdomain=your-coach-cs
  if (hostname === "localhost") {
    const params = new URLSearchParams(window.location.search);
    subdomain = params.get("subdomain") || "your-coach";
  } else {
    // for prod
    subdomain = hostname.split(".")[0];
  }

  const baseRoute = (children) => [
    {
      path: "/",
      element: <MainLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <CoachDefault assistantId={currentAssistantId} />,
        },
        ...children, // check individual subdomain child routes
      ],
    },
  ];

  // for individual subdomain child routes
  const routes = {
    "your-coach": baseRoute([
      {
        path: "sample",
        element: <SamplePage />,
      },
    ]),
    "your-coach-cs": baseRoute([
      {
        path: "demo",
        element: <RoleplayLayout />,
        children: [
          {
            index: true,
            element: <RoleplayDashboard />,
          },
          {
            path: "practice",
            element: <PracticePage />,
          },
        ],
      },
    ]),
    "your-coach-des": baseRoute([
      {
        path: "sample-des",
        element: <SamplePageDes />,
      },
    ]),
    "sales-coach": baseRoute([
      {
        path: "demo",
        element: <RoleplayLayout />,
        children: [
          {
            index: true,
            element: <RoleplayDashboard />,
          },
          {
            path: "practice",
            element: <PracticePage />,
          },
        ],
      },
    ]),
  };

  const router = createBrowserRouter(routes[subdomain] || routes["your-coach"]);

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
}

export default App;
