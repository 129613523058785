export default function CategoryCard({ category, onClick }) {
  return (
    <div
      className='flex flex-col justify-evenly bg-white dark:bg-rpDark dark:text-white rounded-lg p-6 shadow-md hover:bg-rpPrimary dark:hover:bg-rpAccentBrown hover:scale-95 transition-shadow cursor-pointer z-20'
      onClick={onClick}
    >
      <h2 className='text-xl font-semibold text-rpAccent mb-3'>
        {category.category}
      </h2>
      <p className='text-default text-pretty'>{category.prospectInfo}</p>
      <div className='mt-4 flex gap-4'>
        {category.details.map((detail, detailIndex) => (
          <span
            key={detailIndex}
            className='grid place-items-center text-center bg-rpAccent/10 text-rpAccent px-3 py-1 rounded-full text-sm'
          >
            {detail.title}
          </span>
        ))}
      </div>
    </div>
  );
}
