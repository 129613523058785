export const RoleplayCardDetails = [
  {
    category: "Payment processing",
    prospectInfo:
      "HotelsAtHome is a marketplace for unique homes and boutique hotel experiences, similar to Airbnb. They have a global business with over 20,000 accommodations.",
    details: [
      {
        title: "Discovery",
        description:
          "Prepare for an initial discovery call with the founder of HotelsAtHome.",
        goal: "Your goal for this call is to discover the founder’s key pain points and objectives for growth, particularly in areas where your solution could provide value.",
        image: "/Outre7.svg",
        assistantId: "43e1ba26-f120-4b53-b0e4-912ede757b0f",
      },
      {
        title: "Objection handling",
        description:
          "Prepare to tackle objections head-on in this roleplay with the founder of HotelsAtHome.",
        goal: "Your goal for this roleplay is to practice responding to common objections in a thoughtful, solution-oriented way. The aim is to build trust, keep the conversation productive, and guide the founder toward seeing how your solution can address their unique challenges.",
        image: "/Outre14.svg",
        assistantId: "c93a108a-eee6-4730-b4ea-455ee7bfd8c9",
      },
    ],
  },
  {
    category: "BNPL",
    prospectInfo:
      "OutfitFinancing is an e-commerce platform that specializes in high-end, custom-tailored clothing. Known for its commitment to quality and luxury, OutfitFinancing partners with leading fashion brands to offer curated collections for discerning customers.",
    details: [
      {
        title: "Discovery",
        description:
          "Prepare for an initial discovery call with the founder at OutfitFinancing.",
        goal: "Your goal for this call is to uncover the founder’s key pain points, especially in areas where your BNPL solution can help increase average order value and improve the customer purchasing experience.",
        image: "/Outre7.svg",
        assistantId: "c33cfdde-7567-4037-ba59-7dfdce0b95ac",
      },
      {
        title: "Objection handling",
        description:
          "Prepare to tackle objections head-on in this roleplay with the founder of OutfitFinancing.",
        goal: "Your goal for this roleplay is to practice responding to common objections in a thoughtful, solution-oriented way. The aim is to build trust, keep the conversation productive, and guide the founder toward seeing how your solution can address their unique challenges.",
        image: "/Outre14.svg",
        assistantId: "26d83dd8-5298-4d4d-8305-62554b54b016",
      },
    ],
  },
  {
    category: "Spend management",
    prospectInfo:
      "FlexiFleet is a start-up that provides on-demand vehicle rentals for businesses. With operations across multiple cities, FlexiFleet offers flexible solutions for companies needing transportation for employees, deliveries, or client services.",
    details: [
      {
        title: "Discovery",
        description:
          "Prepare for an initial discovery call with the founder of FlexiFleet.",
        goal: "Your goal for this call is to identify the founder’s pain points related to expense tracking, budgeting, and reporting, and explore how your spend management solution can support FlexiFleet's growth and operational efficiency.",
        image: "/Outre7.svg",
        assistantId: "d0772dc6-b491-4b83-9f91-a845801cd2f9",
      },
      {
        title: "Objection handling",
        description:
          "Prepare to tackle objections head-on in this roleplay with the founder of FlexiFleet.",
        goal: "Your goal for this roleplay is to practice responding to common objections in a thoughtful, solution-oriented way. The aim is to build trust, keep the conversation productive, and guide the founder toward seeing how your solution can address their unique challenges.",
        image: "/Outre14.svg",
        assistantId: "96f8cfdb-2380-46e2-8b28-4156f899234c",
      },
    ],
  },
  {
    category: "Loans",
    prospectInfo:
      "CraftWorks is a small business that designs and sells custom-made furniture, focusing on quality craftsmanship and sustainable materials. With growing demand, the founder is looking for loan options to finance larger orders, purchase new equipment, and expand their workshop space.",
    details: [
      {
        title: "Discovery",
        description:
          "Prepare for an initial discovery call with the founder of CraftWorks.",
        goal: "Your goal for this call is to discover the founder's primary financial challenges and growth objectives, especially in areas where your loan solutions could help improve cash flow, increase production capacity, and fuel CraftWorks’ expansion.",
        image: "/Outre7.svg",
        assistantId: "f534b400-49b2-4a68-8a2c-b658d8a7bcab",
      },
      {
        title: "Objection handling",
        description:
          "Prepare to tackle objections head-on in this roleplay with the founder of CraftWorks.",
        goal: "Your goal for this roleplay is to practice responding to common objections in a thoughtful, solution-oriented way. The aim is to build trust, keep the conversation productive, and guide the founder toward seeing how your solution can address their unique challenges.",
        image: "/Outre14.svg",
        assistantId: "5e250b36-bc98-4734-805d-bc839de4effa",
      },
    ],
  },
];

export const transformAnalysis = (data, name) => {
  const lunaSalutations = ["Great to hear from you, Luna!", "Hey Luna"];
  // remove any text before "Hey there,"
  const cleanedSummary = data.summary
    .replace(/^.*?(Hey there)/s, "Hey there")
    .replace(/Hey there/, `Hey ${name}`);

  if (
    (data?.summary &&
      lunaSalutations.some((salutation) =>
        data.summary.includes(salutation)
      )) ||
    Object.keys(data.structuredData).length === 0
  ) {
    return `Hey ${name},\n\nI apologize, but there is not enough information in this brief transcript to properly evaluate the success of the call or your techniques. The snippet only contains an introduction from the customer, with no response or interaction from you visible.\n\nWithout seeing how you handle the customer's questions and concerns, it's impossible to assess your skills or the call's outcome. A rating cannot be provided based on this limited information.\n\nNevertheless, I'm here if you want to role-play or discuss strategies for making the most of these quick interactions.\n\n\nBest,\nPablo`;
  } else {
    return cleanedSummary;
  }
};
