// import AssistantSpeechIndicator from "./call/AssistantSpeechIndicator";
// import Button from "./base/Button";
// import VolumeLevel from "./call/VolumeLevel";
import CallEndIcon from "@mui/icons-material/CallEnd";
import Button from "./base/Button";

const ActiveCallDetail = ({
  assistantIsSpeaking,
  volumeLevel,
  onEndCallClick,
}) => {
  return (
    <div className='flex flex-col items-center justify-center'>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "15px",
          border: "1px solid #ddd",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
          width: "400px",
          height: "200px",
        }}
      >
        <AssistantSpeechIndicator isSpeaking={assistantIsSpeaking} />
        <VolumeLevel volume={volumeLevel} />
      </div> */}
      {/* <img src='/favicon.svg' alt='active call' className='mx-auto w-[200px]' /> */}

      <div className='w-[280px] aspect-square animation-pulse grid place-items-center relative overflow-hidden'>
        <div className='rounded-full aspect-square w-[180px] bg-white dark:bg-dark absolute -right-0 top-6 z-20 animate-moonAnimation'></div>
        <dotlottie-player
          src='https://lottie.host/d1dcc974-272d-4050-999a-fb385117d3cf/ZtQrHWAoEw.json'
          background='transparent'
          speed='0.5'
          style={{
            width: "100%",
            height: "100%",
            paddingLeft: "12px",
          }}
          loop
          autoplay
        ></dotlottie-player>
      </div>

      <Button
        label={
          <span className='button__text'>
            <CallEndIcon /> End
          </span>
        }
        onClick={onEndCallClick}
        className={"submit-button w-[180px] dark:text-red-600"}
      />
    </div>
  );
};

export default ActiveCallDetail;
