import React, { useState } from "react";

function Navbar({ categories, selectedCategory, setSelectedCategory }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className='w-[calc(100%-180px)] relative'>
      {/* Hamburger Menu Button - Mobile Only */}
      <button
        className='fixed top-6 left-6 xl:hidden w-fit py-3 px-4 bg-rpAccent text-white mb-2 rounded flex items-center justify-between'
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <div className='space-y-1.5 cursor-pointer'>
          <span
            className={`block h-0.5 w-6 bg-current transform transition-all duration-300
              ${isMenuOpen ? "rotate-45 translate-y-2" : ""}`}
          ></span>
          <span
            className={`block h-0.5 w-6 bg-current transition-all duration-300
              ${isMenuOpen ? "opacity-0" : ""}`}
          ></span>
          <span
            className={`block h-0.5 w-6 bg-current transform transition-all duration-300
              ${isMenuOpen ? "-rotate-45 -translate-y-2" : ""}`}
          ></span>
        </div>
      </button>

      {/* Backdrop for mobile */}
      {isMenuOpen && (
        <div
          className='fixed inset-0 bg-black/50 xl:hidden z-40'
          onClick={() => setIsMenuOpen(false)}
        />
      )}

      {/* Mobile Menu */}
      <div
        className={`
          xl:hidden fixed inset-x-0 bottom-0 w-full h-[80%] bg-rpSecondary dark:bg-rpDark
          flex flex-col items-center justify-center gap-4 
          p-10 transition-all duration-300 z-50 shadow-lg rounded-xl
          ${
            isMenuOpen
              ? "opacity-100 visible translate-y-0"
              : "opacity-0 invisible -translate-y-4"
          }
        `}
      >
        {categories.map((category) => (
          <div
            key={category}
            className={`
              rpButtons__category w-full max-w-[450px]
              p-3 rounded-md
              hover:bg-rpPrimary/10
              transition-colors duration-200
              ${
                selectedCategory === category
                  ? ""
                  : "rpButtons__category--inactive"
              }
            `}
            onClick={() => {
              setSelectedCategory(category);
              setIsMenuOpen(false);
            }}
          >
            <p className='cursor-pointer text-center'>{category}</p>
          </div>
        ))}
      </div>

      {/* Desktop Menu */}
      <div className='hidden xl:flex h-[70px] items-center justify-end gap-2 px-10'>
        {categories.map((category) => (
          <div
            key={category}
            className={`rpButtons__category ${
              selectedCategory === category
                ? ""
                : "rpButtons__category--inactive"
            }`}
            onClick={() => setSelectedCategory(category)}
          >
            <p className='cursor-pointer'>{category}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Navbar;
